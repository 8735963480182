var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "transform-scale-6 ms-n7" },
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6 mt-16", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "position-relative z-index-2 mt-16 pt-16" },
            [
              _c(
                "v-col",
                { attrs: { md: "7" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "bg-transparent mb-6 px-4 py-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { lg: "6" } }, [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "text-h2 text-typo font-weight-bolder mb-2",
                              },
                              [_vm._v(" General Statistics ")]
                            ),
                            _c("div", { staticClass: "d-flex align-center" }, [
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "font-weight-bolder mb-0 text-h6 text-typo",
                                },
                                [_vm._v(" All users ")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-primary text-sm font-weight-bold mb-0 icon-move-right ms-4 mt-1 text-decoration-none",
                                  attrs: { href: "javascript:;" },
                                },
                                [
                                  _vm._v(" Read More "),
                                  _c("i", {
                                    staticClass:
                                      "fas fa-arrow-right text-sm ms-1",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              ),
                            ]),
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "font-weight-bolder text-h1 mb-0 text-typo",
                              },
                              [_vm._v(" 1,600,000 ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "mb-6 card-shadow border-radius-xl px-4 py-4",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-col", { attrs: { sm: "8" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm mb-0 text-capitalize font-weight-bold text-body",
                                      },
                                      [_vm._v(" Today's Money ")]
                                    ),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "text-h5 text-typo font-weight-bolder mb-0",
                                      },
                                      [
                                        _vm._v(" $53,000 "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-success text-sm font-weight-bolder",
                                          },
                                          [_vm._v("+55%")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "4" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "shadow",
                                          attrs: {
                                            color: "bg-gradient-primary",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "text-white",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v(" ni ni-money-coins ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl px-4 py-4",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-col", { attrs: { sm: "8" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm mb-0 text-capitalize font-weight-bold text-body",
                                      },
                                      [_vm._v(" Today's Users ")]
                                    ),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "text-h5 text-typo font-weight-bolder mb-0",
                                      },
                                      [
                                        _vm._v(" $2,300 "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-success text-sm font-weight-bolder",
                                          },
                                          [_vm._v("+3%")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "4" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "shadow",
                                          attrs: {
                                            color: "bg-gradient-primary",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "text-white",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v(" ni ni-world ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "mb-6 card-shadow border-radius-xl px-4 py-4",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-col", { attrs: { sm: "8" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm mb-0 text-capitalize font-weight-bold text-body",
                                      },
                                      [_vm._v(" New Clients ")]
                                    ),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "text-h5 text-typo font-weight-bolder mb-0",
                                      },
                                      [
                                        _vm._v(" +3,462 "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-danger text-sm font-weight-bolder",
                                          },
                                          [_vm._v("-2%")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "4" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "shadow",
                                          attrs: {
                                            color: "bg-gradient-primary",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "text-white",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v(" ni ni-paper-diploma ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl px-4 py-4",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-col", { attrs: { sm: "8" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm mb-0 text-capitalize font-weight-bold text-body",
                                      },
                                      [_vm._v(" Sales ")]
                                    ),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "text-h5 text-typo font-weight-bolder mb-0",
                                      },
                                      [
                                        _vm._v(" $103,430 "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-success text-sm font-weight-bolder",
                                          },
                                          [_vm._v("+5%")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "4" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "shadow",
                                          attrs: {
                                            color: "bg-gradient-primary",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "text-white",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v(" ni ni-cart ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [_c("sales-country")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [_c("v-col", { attrs: { cols: "12" } }, [_c("widget-globe")], 1)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }